import React, { useState, useEffect, useContext } from 'react';
import swal from 'sweetalert';
// import { Input, Button } from 'antd';
// import validator from 'validator';
// import { UploadOutlined } from '@ant-design/icons';
import { customBaseUrl, httpPost } from '../../../action/http';
import frontArrow from '../../../assets/image/front_arrow.svg';
import backArrow from '../../../assets/image/back_arrow.svg';
import check from '../../../assets/image/check.svg';
import pending from '../../../assets/image/pending.svg';
import rejected from '../../../assets/image/rejected.svg';
import upload from '../../../assets/image/upload.svg';
import { ProfileContext } from '../../../store/context/ProfileContext';
import UploadModal from './UploadModal';
import { hideLoader, showLoader } from '../../../utils/loader';
import VerifyEmailAndPhone from './VerifyEmailAndPhone';
import KYCUploadMode from './uploadModeModal';

const UploadTier = (props) => {
  const { kycStatus } = props;
  const {
    userDetails,
    profile: { firstName, surname, email: uEmail, phoneNumber },
  } = useContext(ProfileContext);
  const [showModal, hideModal] = useState(false);
  const [postData, setPostData] = useState({});
  //  const [bvnStat, setBvnStat] = useState();
  const [loading, setLoading] = useState(false);
  const [idType, setIdType] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [name, setName] = useState('');
  //  const [trnxlevels, settrnxLevels] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');
  const [userAddress] = useState();
  //  const [tin, setTin] = useState();
  const [mermatStatus] = useState();
  const [tinStatus] = useState();
  /* const [level1, setLevel1] = useState([]);
  const [level2, setLevel2] = useState([]);
  const [level3, setLevel3] = useState([]);
  const [level4, setLevel4] = useState([]); */
  console.log('kycStatus', kycStatus);
  const [page, setPage] = useState(1);
  const [docs, setDocs] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [bvn, setBvn] = useState([]);
  const [passport, setPassport] = useState([]);
  const [id, setId] = useState([]);
  const [utility, setUtility] = useState([]);
  const [cac, setCac] = useState([]);
  const [tin, setTin] = useState([]);
  const [reference, setReference] = useState([]);
  const [showUploadMode, setShowUploadMode] = useState(false);
  const [uploadMode, setUploadMode] = useState('auto');

  // let {userCAC, userInternationalPassport, userPassportPhotograph, userDriversLicense, userNationalId,userVotersCard, usersUtilityBill} = kycStatus;
  const {
    userPassportPhotograph,
    userInternationalPassport,
    userDriversLicense,
    userNationalId,
    userVotersCard,
    userCAC,
    TIN,
  } = kycStatus || {};

  /*  const getAnyDoc = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/upload-any-document/find-one-document/${userDetails.userId}`
    );
    console.log('anyDoc', res);
    setUploadDoc(res);
    console.log('uploadDoc', uploadDoc);
    if (res.fileName === 'MERMAT') {
      setMermatStatus(res.adminStatus);
      console.log('mermat', mermatStatus);
    }
    if (res.fileName === 'TIN') {
      setTinStatus(res.adminStatus);
      console.log('TinStatus', tinStatus);
    }
  }; */
  // const getMermat = () => {
  //   if (uploadDoc.length) {
  //     const findMermat = uploadDoc.find((e) => e.fileName === 'MERMAT');
  //     setMermatStatus(findMermat.adminStatus);
  //     console.log('mermatStatus', mermatStatus);
  //   }
  // };
  const handlePage = (type) => {
    if (type === 'previous') {
      setPage(page - 1);
    }
    if (type === 'next') {
      setPage(page + 1);
    }
  };

  /*  const handleTin = async () => {
    if (validator.isEmpty(tin)) {
      swal('Oops!', 'TIN cannot be empty', 'error');
      return;
    }

    if (/\D/.test(tin)) {
      swal('Oops!', 'Only numbers are allowed', 'error');
      return;
    }

    if (tin.length < 9) {
      swal('Oops!', 'TIN cannot be be more than 9 numbers', 'error');
      return;
    }

    const res = await httpPost(
      `/upload-any-document/other-requirement?id=${userDetails.userId}&name=TIN&value=${tin}`,
      tin,
      customBaseUrl.kycUrl
    );
    if (res.status) {
      swal('Done', res.message, 'success').then(() => {
        hideLoader();
        hideModal(false);
        setLoading(false);
        setPostData({});
        setPreviewImage(null);
        setTin();
      });
    } else {
      hideLoader();
      swal('Oops!', res.message, 'error');
      setLoading(false);
    }
  }; */
  const getKYCDoc = async () => {
    const res = await httpPost(
      `/api/v1/kyc/requirementData/${userDetails.userId}`,
      {},
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      // console.log('bvn', res);
      if (res.data) {
        setDocs(res?.data);
        const email1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'EMAIL'
        )[0];
        const phone1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'PHONE_NUMBER'
        )[0];
        const bvn1 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'BVN'
        )[0];
        const passport1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'PHOTO'
        )[0];
        const id1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'ID'
        )[0];
        const utility1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'UTILITY'
        )[0];
        const cac1 = res?.data?.tier4?.filter(
          (e) => e?.requirementType === 'CAC'
        )[0];
        const tin1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'TIN'
        )[0];
        const reference1 = res?.data?.tier3?.filter(
          (e) => e?.requirementType === 'REFERENCE'
        )[0];
        console.log('bvn', bvn1);
        console.log('bvnph', phone1);
        console.log('bvnem', email1);
        setEmail(email1);
        setPhone(phone1);
        setBvn(bvn1);
        setPassport(passport1);
        setId(id1);
        setUtility(utility1);
        setCac(cac1);
        setTin(tin1);
        setReference(reference1);
      } else {
        console.log(res, reference, docs);
      }
    }
  };
  const callManualUpload = async (item) => {
    showLoader();
    const data = {
      attachment: 'string',
      data: {
        message: `The user with the following information is requesting for  ${item} manual verification on the admin dashboard`,
        names: [
          {
            email: process.env.KYC_EMAIL ?? 'wayaadmin@yopmail.com',
            fullName: 'Waya Admin',
          },
        ],
      },
      eventCategory: 'WELCOME',
      eventType: 'EMAIL',
      htmlCode: `<p>Email: ${uEmail}</p><p>Name: ${firstName} ${surname}</p> <p>UserId: ${userDetails.userId}`,
      initiator: uEmail,
      phoneNumber,
      productType: 'WAYABANK',
      subject: `Request for manual ${item} Verification`,
    };
    const res = await httpPost(
      '/email-notification',
      data,
      customBaseUrl.notificationUrl
    );
    if (res.status === true) {
      hideLoader();
      hideModal(false);
    } else {
      swal('Oops!', res?.message ?? 'error', 'error');
      hideLoader();
    }
  };
  const handleUploadDoc = async (type, image) => {
    const d = new Date();
    setLoading(true);
    showLoader();
    const newForm = new FormData();
    newForm.append('file', image);
    newForm.append('userId', userDetails?.userId);
    newForm.append('identificationNumber', postData?.identificationNumber);

    if (type === 'utility') {
      /*  newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/utility/bill/create/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      } */
      newForm.append('fileName', 'UTILITY');
      newForm.append('identificationNumber', d.getTime());
      newForm.append('id', utility?.id);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status === true) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          getKYCDoc();
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('utility');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'photograph') {
      newForm.append('fileName', 'PHOTO');
      newForm.append('identificationNumber', d.getTime());
      newForm.append('id', passport?.id);
      const res = await httpPost(
        `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.status) {
        swal('Done', res?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
          getKYCDoc();
        });
        if (uploadMode === 'manual') {
          callManualUpload('photograph');
        }
      } else {
        hideLoader();
        swal('Oops!', res?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'Address') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/address/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
        if (uploadMode === 'manual') {
          callManualUpload('Address');
        }
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'cac') {
      newForm.append('fileName', 'CAC');
      const res = await httpPost(
        `/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
          getKYCDoc();
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }
    if (type === 'tin') {
      newForm.append('fileName', 'TIN');
      const res = await httpPost(
        `/kyc/upload/documents/${userDetails?.userId}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res?.body?.status) {
        swal('Done', res?.body?.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
          getKYCDoc();
        });
      } else {
        hideLoader();
        swal('Oops!', res?.body?.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'MERMAT') {
      newForm.append('fileName', postData?.fileName);
      const res = await httpPost(
        `/upload-any-document/${userDetails?.userId}?fileName=${image?.name}`,
        newForm,
        customBaseUrl.kycUrl
      );
      if (res.status) {
        swal('Done', res.message, 'success').then(() => {
          hideLoader();
          hideModal(false);
          setLoading(false);
          setPostData({});
          setPreviewImage(null);
        });
      } else {
        hideLoader();
        swal('Oops!', res.message, 'error');
        setLoading(false);
      }
    }

    if (type === 'ID') {
      newForm.append('id', id?.id);
      if (idType === 'interPass') {
        newForm.append('fileName', 'INTERNATIONAL_PASSPORT');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            getKYCDoc();
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'nationalId') {
        newForm.append('fileName', 'NIN');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.status) {
          swal('Done', res?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            setPreviewImage(null);
            getKYCDoc();
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'votersCard') {
        newForm.append('fileName', 'VOTERS_CARD');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            getKYCDoc();
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
      if (idType === 'driversLicence') {
        newForm.append('fileName', 'DRIVER_LICENSE');
        const res = await httpPost(
          `/api/v1/kyc/upload/documents/${userDetails?.userId}`,
          newForm,
          customBaseUrl.kycUrl
        );
        if (res?.body?.status) {
          swal('Done', res?.body?.message, 'success').then(() => {
            hideLoader();
            hideModal(false);
            setLoading(false);
            setPostData({});
            getKYCDoc();
            setPreviewImage(null);
          });
          if (uploadMode === 'manual') {
            callManualUpload('ID');
          }
        } else {
          hideLoader();
          swal('Oops!', res?.body?.message ?? res?.message, 'error');
          setLoading(false);
        }
      }
    }
    setLoading(false);
    hideLoader();
  };

  /* const getBvnStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/isBvnLinked/${userDetails.userId}`
    );
    if (res.status) {
      // console.log('bvn', res);
      if (res.data) {
        setBvnStat(res?.data);
      } else {
        setBvnStat(null);
      }
    }
  }; */

  /*  const getAddressStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/address/user/${userDetails.userId}`
    );
    if (res?.status) {
      if (res.data) {
        setUserAddress(res.data === true ? res.data : res.data.validated);
      } else {
        setUserAddress(null);
      }
    }
  };

  /*  const getUtilityBillStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/getUserKycData/utilityBill/${userDetails.userId}`
    );
    if (res?.status) {
      setUtilityBillStat(res.data);
      console.log('utility>>', utilityBillStat);
    }
    return [];
  }; */

  /*  const getTransactionLevels = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetchAllTransactionLevels`
    );
    if (res.status) {
      settrnxLevels(res.data);
      console.log('transAll', trnxlevels);
      setLevel1(res?.data?.filter((e) => e?.tiers === 'tier1')[0]);
      setLevel2(res?.data?.filter((e) => e?.tiers === 'tier2')[0]);
      setLevel3(res?.data?.filter((e) => e?.tiers === 'tier3')[0]);
      setLevel4(res?.data?.filter((e) => e?.tiers === 'tier4')[0]);
    }
    return [];
  }; */
  useEffect(() => {
    //  getBvnStatus();
    getKYCDoc();
    //  getTransactionLevels();
    // getUtilityBillStatus();
    // getAddressStatus();
    // getAnyDoc();
  }, []);
  // useEffect(() => {
  //   if (page === 3) setShowUploadMode(true);
  // }, [page]);

  return (
    <div>
      {page === 1 ? (
        <div className="tier" id="tier1">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 1</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  phone?.status === 'APPROVED' || email?.status === 'APPROVED'
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {phone?.status === 'APPROVED' || email?.status === 'APPROVED'
                  ? 'COMPLETED'
                  : 'NOT COMPLETED'}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">Phone Number Verification</td>
                  <td>
                    <div
                      onClick={() => {
                        setShowAlertType('verifyPhone');
                        setShowAlert(true);
                      }}
                      className={`action ${
                        phone?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                      } my-2`}
                    >
                      <img src={frontArrow} alt="" className="me-2 " />
                      <span>
                        {phone?.status === 'APPROVED' ? 'Verified' : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        phone?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={phone?.status === 'APPROVED' ? check : pending}
                        alt=""
                        className={`me-2 ${
                          phone?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>{phone?.status}</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Email Verification</td>
                  <td>
                    <div
                      onClick={() => {
                        setShowAlertType('verifyEmail');
                        setShowAlert(true);
                      }}
                      className={`action ${
                        email?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                      } my-2`}
                    >
                      <img src={frontArrow} alt="" className="me-2" />
                      <span>
                        {' '}
                        {email?.status === 'APPROVED' ? 'Verified' : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        email?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={email?.status === 'APPROVED' ? check : pending}
                        alt=""
                        className={`me-2 ${
                          email?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>{email?.status}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({phone?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{phone?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {phone?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${
                  page === 4 &&
                  phone?.status === 'APPROVED' &&
                  email?.status === 'APPROVED'
                    ? 'btn disabled'
                    : 'btn'
                }`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : page === 2 ? (
        <div className="tier" id="tier2">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 2</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  bvn?.status === 'APPROVED'
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {bvn?.status === 'APPROVED' ? 'Completed' : 'Not Completed'}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">BVN Validation</td>
                  <td>
                    <div
                      className={`action ${
                        bvn?.status === 'APPROVED' ? 'btn disabled' : 'btn'
                      } my-2`}
                      onClick={() => {
                        setShowAlertType('bvn');
                        setShowAlert(true);
                      }}
                    >
                      <img src={frontArrow} alt="" className="me-2" />
                      <span>
                        {bvn?.status === 'APPROVED' ? 'Verified' : 'Verify'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        bvn?.status === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={bvn?.status === 'APPROVED' ? check : pending}
                        alt=""
                        className={`me-2 ${
                          bvn?.status === 'APPROVED'
                            ? 'status-true'
                            : 'status-pending'
                        }`}
                      />
                      <span>{bvn?.status}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({bvn?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{bvn?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {bvn?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${
                  page === 4 || bvn?.status !== 'APPROVED'
                    ? 'btn disabled'
                    : 'btn'
                }`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : page === 3 ? (
        <div className="tier" id="tier3">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 3</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  id?.status === 'APPROVED' ? 'info-2-b-true' : 'info-2-b-false'
                } px-3 py-1`}
              >
                {id.status === 'APPROVED' ? 'Completed' : 'Not Completed'}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">Passport Photograph</td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('photograph');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {passport?.status === 'APPROVED'
                          ? 'Uploaded'
                          : 'Upload'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        passport?.status === 'APPROVED'
                          ? 'status-true'
                          : passport?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          passport?.status === 'APPROVED'
                            ? check
                            : passport?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{passport?.status}</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">
                    Passport / voters card / NIN / Drivers License
                  </td>
                  <td className="d-flex flex-column">
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('ID');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {id?.status === 'APPROVED' ? 'Uploaded' : 'Upload'}
                      </span>
                    </div>
                    {/*  <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('driverLicense');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>

                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('nationalId');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('voterCard');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div> */}
                  </td>
                  <td>
                    {/* <div className="status mb-3">
                      <img src={check} alt="" className="me-2" />
                      <span>{kycStatus?.internationalPassport?.status}</span>
                    </div>
                    <div className="status mb-3">
                      <img src={check} alt="" className="me-2" />
                      <span>{kycStatus?.driverLicense?.status}</span>
                    </div> */}
                    <div
                      className={
                        id?.status === 'APPROVED'
                          ? 'status-true'
                          : id?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          id.status === 'APPROVED'
                            ? check
                            : id?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{id?.status}</span>
                    </div>
                    {/* <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>{kycStatus?.voterCard?.status}</span>
                    </div> */}
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">
                    <div>
                      <p>Utility bill</p>
                      <p>
                        (Any TV subscription bills, any internet subscription
                        bills, bank statement)
                      </p>
                    </div>
                  </td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('utility');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>
                        {utility?.status === 'APPROVED' ? 'Uploaded' : 'Upload'}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        utility?.status === 'APPROVED'
                          ? 'status-true'
                          : utility?.status === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          utility?.status === 'APPROVED'
                            ? check
                            : utility?.status === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{utility?.status}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({id?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{id?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {id?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="col">
              <div className="tier-foot-1">
                <p className="limit-info fw-bold my-3"> NB: </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – sole proprietorship:
                </p>
                <p> Upload Any 1 Government ID for the director. </p>
                <p className="limit-info fw-bold my-3">
                  For Business users – LTD/PLC and above:
                </p>
                <p>
                  Upload at least 2 Government ID of the directors - Scanned to
                  one file as PDF and upload once.
                </p>
              </div>
              <div className="tier-foot-1">
                Supported files for upload includes, jpeg, png, pdf
              </div>
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${
                  page !== 4 &&
                  userPassportPhotograph?.adminStatus === 'APPROVED' &&
                  utility?.status === 'APPROVED' &&
                  (userVotersCard?.adminStatus === 'APPROVED' ||
                    userInternationalPassport?.adminStatus === 'APPROVED' ||
                    userNationalId?.adminStatus === 'APPROVED' ||
                    userDriversLicense?.adminStatus === 'APPROVED')
                    ? 'btn'
                    : 'btn disabled'
                }`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : page === 4 ? (
        <div className="tier" id="tier4">
          <div className="head my-5">Upgrade KYC Level</div>
          <div className="info my-3">
            <p className="info-1">Tier 4</p>
            <div className="info-2">
              <p className="info-2-t">status</p>
              <p
                className={`${
                  userAddress === true &&
                  kycStatus?.CAC?.status === true &&
                  tinStatus !== null &&
                  mermatStatus !== null
                    ? 'info-2-b-true'
                    : 'info-2-b-false'
                } px-3 py-1`}
              >
                {userAddress === true &&
                kycStatus?.CAC?.status === true &&
                tinStatus !== null &&
                mermatStatus !== null
                  ? 'Completed'
                  : 'Not Completed'}
              </p>
            </div>
          </div>
          <div className="table my-2">
            <table className="w-100">
              <thead>
                <tr className="text-center ">
                  <th className="p-2 w-50">Kyc Requirement</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td className="p-3">
                    Address verification - photo of the street, house number,
                    left and right
                  </td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('Address');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        userAddress === true ? 'status-true' : 'status-pending'
                      }
                    >
                      <img
                        src={userAddress === true ? check : pending}
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {userAddress === true ? 'Verified' : 'Pending'}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">CAC Certificate </td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('cac');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                  </td>
                  <td>
                    <div
                      className={
                        userCAC?.adminStatus === 'APPROVED'
                          ? 'status-true'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          userCAC?.adminStatus === 'APPROVED'
                            ? check
                            : userCAC?.adminStatus === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{cac?.status}</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Tax Indentification Number</td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('tin');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                    {/* <div>
                      <Input
                        type="number"
                        style={{ width: 100 }}
                        placeholder="TIN"
                        value={tin}
                        onChange={(e) => setTin(e.target.value)}
                      />
                      <Button
                        onClick={handleTin}
                        shape="circle"
                        icon={<UploadOutlined />}
                        size="small"
                      />
                    </div> */}
                  </td>
                  <td>
                    {/* <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>Approved</span>
                    </div> */}
                    <div
                      className={
                        TIN.adminStatus === 'APPROVED'
                          ? 'status-true'
                          : TIN.adminStatus === 'REJECTED'
                          ? 'status-false'
                          : 'status-pending'
                      }
                    >
                      <img
                        src={
                          TIN.adminStatus === 'APPROVED'
                            ? check
                            : TIN.adminStatus === 'REJECTED'
                            ? rejected
                            : pending
                        }
                        alt=""
                        className="me-2"
                      />
                      <span>{tin?.status}</span>
                    </div>
                  </td>
                </tr>
                <tr className="">
                  <td className="p-3">Mermat</td>
                  <td>
                    <div
                      className="action btn my-2"
                      onClick={() => {
                        hideModal(true);
                        setName('MERMAT');
                      }}
                    >
                      <img src={upload} alt="" className="me-2" />
                      <span>Upload</span>
                    </div>
                  </td>
                  <td>
                    {/* <div className="status">
                      <img src={check} alt="" className="me-2" />
                      <span>Approved</span>
                    </div> */}
                    <div
                      className={
                        mermatStatus === null ? 'status-pending' : 'status-true'
                      }
                    >
                      <img
                        src={mermatStatus === null ? pending : check}
                        alt=""
                        className="me-2"
                      />
                      <span>
                        {mermatStatus === null ? 'Pending' : [mermatStatus]}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="limit">
            <p className="limit-info fw-bold my-3">You are limited to:</p>
            <p className="limit-info">
              Daily Number of Transfers- ({tin?.dailyTransactionLimitCount}){' '}
            </p>
            <p className="limit-info">
              {' '}
              Daily limit (N{tin?.dailyTransactionLimit}){' '}
            </p>
            <p className="limit-info">
              Single Transfer limit (N
              {tin?.singleTransactionLimit}){' '}
            </p>
          </div>
          <div className="tier-foot mb-2">
            <div className="tier-foot-1">
              NB: Supported files for upload includes, jpeg, png, pdf
            </div>
            <div className="tier-foot-2 me-0">
              <div
                className={`${page === 1 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('previous');
                }}
              >
                <img className="me-2" src={backArrow} alt="" /> Previous
              </div>{' '}
              <div className="page mx-1">{page}</div>{' '}
              <div
                className={`${page === 4 ? 'btn disabled ' : 'btn'}`}
                onClick={() => {
                  handlePage('next');
                }}
              >
                Next
                <img className="ms-2" src={frontArrow} alt="" />
              </div>
            </div>
          </div>
          <div style={{ height: 100 }} />
        </div>
      ) : (
        ''
      )}

      {showModal ? (
        <UploadModal
          hideModal={hideModal}
          showModal={showModal}
          handleUploadDoc={handleUploadDoc}
          name={name}
          postData={postData}
          setPostData={setPostData}
          loading={loading}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
          setIdType={setIdType}
          idType={idType}
        />
      ) : (
        ''
      )}
      {showAlert ? (
        <VerifyEmailAndPhone
          showAlert={showAlert}
          type={showAlertType}
          uploadMode={uploadMode}
          setShowAlert={setShowAlert}
          setShowMode={setShowUploadMode}
        />
      ) : (
        ''
      )}
      {showUploadMode ? (
        <KYCUploadMode
          hideModal={setShowUploadMode}
          showModal={showUploadMode}
          uploadMode={uploadMode}
          setUploadMode={setUploadMode}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default UploadTier;
